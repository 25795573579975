
const urlPrefixList ={
    host:import.meta.env.VITE_APP_DEV_BASE_URL,
    api:'wp-json/wp/v2/',
    categoriesPath:'categories',
    tagsPath:'tags',
    acfApi:'wp-json/acf/v2/',
    authApi:'?rest_route=/swd/v1/',
    pmtApi:'wp-json/sw/v1/',
    emailAuth:'wp-json/email-authentication/v1/',
    passwordApi:'wp-json/bdpwr/v1/',
    emailVerifyRequestCodePath:'send-code',
    emailVerifyCreateUserPath:'verify-code',
    emailVerifyValidateUserPath:'validate-user',
    emailUnregisteredDonation:'unregistered',
    passwordReqPath:'reset-password',
    passwordSetPath:'set-password',
    pmtCreatePath:'pmtjs/create',
    stripeInitPath:'pmtjs/intent',
    pmtUpdatePath:'pmtjs/update',
    pmtConfirmPath:'pmtjs/confirm',
    customApi:'wp-json/sw_custom_routes/v1/',
    balancePath:'user_balance',
    creditPath:'credit',        
    usersPath:'users',
    mediaPath:'media',
    rssFeedsPath:'sw_feed',
    rssFeedTaxPath:'source',
    postsPath:'posts',
    pagesPath:'pages',
    postsByLangPath:'posts_by_language',
    randomPostsPath:'random-posts',
    accountPath:'account',
    donationsPath:'donation',
    userDonationsPath:'user_donations',
    userCreditsPath:'user_credits',
    fundraiserPath:'fundraiser',
    orgsPath:'organisationpage',
    fundThemPath:'thematicfund',
    fundPath:'fund',
    busPath:'entreprise',
    orgsListPath:'organisation',
    orgsListPathAlt:'custom_org',
    orgCustomPath:'custom_orgpages',
    thematicFundsCustomPath:'custom_them_fund_pages',
    orgWhereAdminPath:'organisations_where_admin',
    acfPath:'options/',
    authPath:'auth/',
    resetTokenPath:'auth/refresh',
    signInPath:'autologin',
    registerPath:'users',
    psResetCodePath:'users/reset_password',
    fundraiserProgressPath:'fundraiser_progress',
    fundraiserFilteredPath:'filtered_fundraisers',
    orgFundraiserFilteredPath:'org_filtered_fundraisers',
    newsletterAddUserPath:'subscribeUser',
}

/**Set Url prefix for dev */
if (import.meta.env.PROD) {
    urlPrefixList.host = '/';
}

/**
* Build the necessary urls for api calls
* @param name name of the url
* @param arg1 first argument to pass to the url
* @param arg2 second argument to pass to the url 
* @param arg3 third argument to pass to the url 
* @returns a url string to use in the api call
* Note, solution for things like jwt to be defined
*/
 const Urls = (name:any, arg1?: string, arg2?: string,arg3?:string)=> {

    /**Store url construction functions */
    const UrlHandlers:any = {};
    
    /**Function to build userCreditUrl */
    UrlHandlers.userCreditUrl = () => {
        let userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.creditPath}?author=${userId}&acf_format=standard&?per_page=100`;
    }
    /**Create fetch user feed source url */
    UrlHandlers.rssFeedsUrl = () => {
        const sources=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.rssFeedsPath}?${sources}&_embed&per_page=40`;
    }
    UrlHandlers.allFeeds = () => {
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.rssFeedsPath}?_embed&_fields&per_page=40`;
    }
    UrlHandlers.byTaxonomyFeedsUrl = () => {
        const source=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.rssFeedsPath}?_embed&_fields&categories=${source}`;
    }

    UrlHandlers.singleFeedUrl = () => {
        const itemId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.rssFeedsPath}/${itemId}?_embed&_fields`;
    }
    UrlHandlers.feedTaxonomyUrl=()=>{
        const itemId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.rssFeedTaxPath}/${itemId}?_embed&_fields`;
    }
    UrlHandlers.allNews = () => {
        let lan = arg1;
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.postsByLangPath}?lan=${lan}`;
    }
    UrlHandlers.byTaxonomyNewsUrl = () => {
        const source=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.postsPath}?_embed&_fields&categories=${source}`;
    }

    UrlHandlers.singleNewsUrl = () => {
        const name=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.postsPath}?slug=${name}&_embed&_fields`;
    }
    UrlHandlers.NewsTaxonomyUrl=()=>{
        const itemId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.postsPath}/${itemId}?_embed&_fields`;
    }

    UrlHandlers.organizationUrl = () => {
        let categories='';
        let order='orderby=title';
        if(arg1?.length!>0){
            categories=`categories=${arg1}`
        }
 
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.orgsPath}?_embed&acf_format=standard&${order}&order=asc&per_page=99&${categories}`;
    }
 
    UrlHandlers.organizationSingleUrl = () => {
        const orgId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.orgsPath}/${orgId}?_embed&acf_format=standard`;
    }
    UrlHandlers.FundThemeUrl = () => {
        const fundThemeId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.fundThemPath}/${fundThemeId}?_embed&acf_format=standard`;
    }
    UrlHandlers.businessSingleUrl = () => {
        const orgId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.busPath}/${orgId}?_embed&acf_format=standard`;
    }
    UrlHandlers.staticPage = () => {
        const slug=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.pagesPath}?slug=${slug}&_embed`;
    }
    UrlHandlers.organizationSingleBySlugUrl = () => {
        const slug=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.orgsPath}?slug=${slug}&_embed`;

    }
    UrlHandlers.organizationListUrl = () => {
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.orgsListPath}?orderby=title&order=asc&per_page=100`;
    }
    UrlHandlers.organizationSingUrl = () => {
        const orgId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.orgsListPathAlt}?id=${orgId}`;
    }
    UrlHandlers.fundListUrl = () => {
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.fundThemPath}?orderby=title&order=asc&per_page=100`;
    }
    /** */
    UrlHandlers.donationsUrl = () => {
        const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.donationsPath}?author=${userId}&acf_format=standard&orderby=date&order=desc&per_page=100`;
    }
    UrlHandlers.userDonationsUrl = () => {
        const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.userDonationsPath}?per_page="100"&id=${userId}&orderby=date&order=desc`;
    }
    UrlHandlers.userCreditsUrl = () => {
        const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.userCreditsPath}?per_page="100"&id=${userId}&orderby=date&order=desc`;
    }
    UrlHandlers.createDonationUrl = () => {
        //const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.donationsPath}`;
    }
    UrlHandlers.updateDonationUrl = () => {
        //const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.donationsPath}/${arg1}`;
    }
    /** */
    UrlHandlers.createCreditUrl = () => {
        //const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.creditPath}`;
    }
    UrlHandlers.updateCreditUrl = () => {
        //const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.creditPath}/${arg1}`;
    }
    /** */
    UrlHandlers.userfundraiserUrl = () => {
        const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.fundraiserPath}?author=${userId}&acf_format=standard&orderby=title&order=asc&per_page=100`;
    }
    UrlHandlers.allFundraiserUrl = () => {
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.fundraiserFilteredPath}`;
    }
    UrlHandlers.orgFundraiserUrl = () => {
        const id=arg1
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.orgFundraiserFilteredPath}?id=${id}`;
    }
    UrlHandlers.singleFundraiserUrl = () => {
        const itemId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.fundraiserPath}/${itemId}`;
    }
    UrlHandlers.createFundraiserUrl = () => {
        //const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.fundraiserPath}`;
    }
    UrlHandlers.updatFundraiserUrl = () => {
        //const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.fundraiserPath}/${arg1}`;
    }
    /** */
    UrlHandlers.createMediaUrl = () => {
        //const userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.mediaPath}`;
    }
    /** */
    UrlHandlers.optionsUrl = () => {
        return `${urlPrefixList.host}${urlPrefixList.acfApi}${urlPrefixList.acfPath}`;

    }
    UrlHandlers.authenticateUserUrl = () => {
        return `${urlPrefixList.host}${urlPrefixList.authApi}${urlPrefixList.authPath}`;

    }
    UrlHandlers.signInUserUrl = () => {
        return `${urlPrefixList.host}${urlPrefixList.authApi}${urlPrefixList.signInPath}`;

    }
    UrlHandlers.registerUser = () => {
        return `${urlPrefixList.host}${urlPrefixList.authApi}${urlPrefixList.registerPath}`;

    }
    UrlHandlers.updateUserUrl = () => {
       let  userId=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.usersPath}/${userId}`;
    }

    UrlHandlers.getUser = () => {
        let userid=arg1
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.usersPath}/${userid}`;

    }
    UrlHandlers.getPaymentInterface=()=>{
        return `${urlPrefixList.host}${urlPrefixList.pmtApi}${urlPrefixList.pmtCreatePath}`;

    }
    UrlHandlers.updatePayment=()=>{
        return `${urlPrefixList.host}${urlPrefixList.pmtApi}${urlPrefixList.pmtUpdatePath}`;

    }
    UrlHandlers.confirmPayment=()=>{
        return `${urlPrefixList.host}${urlPrefixList.pmtApi}${urlPrefixList.pmtConfirmPath}`;

    }
    UrlHandlers.psRestCodeUrl=()=>{
        return `${urlPrefixList.host}${urlPrefixList.passwordApi}${urlPrefixList.passwordReqPath}`;

    }
    UrlHandlers.resetPsUrl=()=>{
        return `${urlPrefixList.host}${urlPrefixList.passwordApi}${urlPrefixList.passwordSetPath}`;

    }
    UrlHandlers.resetTokenUrl=()=>{
        return `${urlPrefixList.host}${urlPrefixList.authApi}${urlPrefixList.resetTokenPath}`;

    }
    UrlHandlers.userBalanceUrl = () => {
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.balancePath}`;

    }
    UrlHandlers.randomPosts = () => {
        let posttype=arg1;
        let count=arg2;
        let lan=arg3;
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.randomPostsPath}?posttype=${posttype}&count=${count}&lan=${lan}`;

    }
    UrlHandlers.fundraiserProgressUrl = () => {
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.fundraiserProgressPath}`;

    }
    UrlHandlers.listCategories = () => {
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.categoriesPath}?acf_format=standard&per_page=100`;

    }
    UrlHandlers.listCategoriesIn = () => {
        let categories='';
        if(arg1?.length!>0){
            categories=`include=${arg1}`
        }
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.categoriesPath}?acf_format=standard${categories}&per_page=100`;

    }
    UrlHandlers.listTags = () => {
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.tagsPath}?per_page=100`;

    }
    
    UrlHandlers.orgsWhereAdmin=()=>{
        let id=arg1;
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.orgWhereAdminPath}?id=${id}`;

    }
    UrlHandlers.orgsCustomUrl=()=>{
        let categories='';
        if(arg1?.length!>0){
            categories=`cats=${arg1}`
        }
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.orgCustomPath}?${categories}&lan=${arg2}`;

    }
    UrlHandlers.fundSingleBySlugUrl = () => {
        const slug=arg1;
        return `${urlPrefixList.host}${urlPrefixList.api}${urlPrefixList.fundPath}?slug=${slug}&_embed`;

    }
    UrlHandlers.fundsCustomUrl=()=>{
        return `${urlPrefixList.host}${urlPrefixList.customApi}${urlPrefixList.thematicFundsCustomPath}?lan=${arg1}`;

    }
    UrlHandlers.emailSendCodeUrl=()=>{
        //
        let {host,emailVerifyRequestCodePath:path,emailAuth:api}=urlPrefixList
        
        return `${host}${api}${path}` // the Url

    }

    UrlHandlers.verifyUserCodeUrl=()=>{
        //
        let {host,emailVerifyCreateUserPath:path,emailAuth:api}=urlPrefixList

        return `${host}${api}${path}` // the Url
    }

    UrlHandlers.valideUserCodeUrl=()=>{
        //
        let {host,emailVerifyValidateUserPath:path,emailAuth:api}=urlPrefixList

        return `${host}${api}${path}` // the Url

    }

    UrlHandlers.donateUnregistered=()=>{
        //
        let {host,emailUnregisteredDonation:path,emailAuth:api}=urlPrefixList

        return `${host}${api}${path}` // the Url

    }
    UrlHandlers.subscribeUserUrl=()=>{
        //
        let {host,newsletterAddUserPath:path,customApi:api}=urlPrefixList

        return `${host}${api}${path}` // the Url

    }

    UrlHandlers.stripeInit=()=>{
        //extract url element for stripe payment intent request

        return `${urlPrefixList.host}${urlPrefixList.pmtApi}${urlPrefixList.stripeInitPath}`
    }

    return UrlHandlers[name]();


}

export default Urls;